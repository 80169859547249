import React from "react";
import { Link } from "react-router-dom";
import HadesLogo from "../../assets/hades-logo.png";

import telegram from '../../assets/iconsS/telegram.svg'
import youtube from '../../assets/iconsS/youtube.svg'
import coinmarketcap from '../../assets/iconsS/coinmarketcap-1.svg'
import twitter from '../../assets/iconsS/twitter.svg'
import medialog from '../../assets/iconsS/m.svg'
import discord from '../../assets/iconsS/discover.svg'
import linkdin from '../../assets/iconsS/linkdin.svg'

const Footer = () => {
    return (
        <div className="py-5 relative isolate overflow-hidden">
            <div className="absolute left-0 right-0 top-0">
                <img src={require('../../assets/bg-line.png')} className="w-full opacity-25" alt="" />
            </div>
            <div className="flex mt-4 lg:justify-center md:justify-center sm:justify-center justify-center sm:mt-0 lg:gap-3 md:gap-3 sm:gap-2 gap-2 relative">
                <Link to="https://t.me/+OaNSKO_6eacwMGE1" target="_blank" className="transition-all hover:-mt-2 bg-neutral-900 hover:bg-white lg:w-14 md:w-14 sm:w-11 w-11 lg:h-14 md:h-14 sm:h-11 h-11 flex items-center justify-center rounded-full">
                    <img src={telegram} className="w-6 h-6 animate-pulse" />
                </Link> 
                {/* <Link to="/" target="_blank" className="transition-all hover:-mt-2 bg-neutral-900 hover:bg-white lg:w-14 md:w-14 sm:w-11 w-11 lg:h-14 md:h-14 sm:h-11 h-11 flex items-center justify-center rounded-full">
                    <img src={youtube} className="w-6 h-6 animate-pulse" />
                </Link> 
                <Link to="/" target="_blank" className="transition-all hover:-mt-2 bg-neutral-900 hover:bg-white lg:w-14 md:w-14 sm:w-11 w-11 lg:h-14 md:h-14 sm:h-11 h-11 flex items-center justify-center rounded-full">
                    <img src={coinmarketcap} className="w-6 h-6 animate-pulse" />
                </Link>  */}
                <Link to="https://x.com/Hades2893153995" target="_blank" className="transition-all hover:-mt-2 bg-neutral-900 hover:bg-white lg:w-14 md:w-14 sm:w-11 w-11 lg:h-14 md:h-14 sm:h-11 h-11 flex items-center justify-center rounded-full">
                    <img src={twitter} className="w-6 h-6 animate-pulse" />
                </Link> 
                {/* <Link to="/" target="_blank" className="transition-all hover:-mt-2 bg-neutral-900 hover:bg-white lg:w-14 md:w-14 sm:w-11 w-11 lg:h-14 md:h-14 sm:h-11 h-11 flex items-center justify-center rounded-full">
                    <img src={medialog} className="w-6 h-6 animate-pulse" />
                </Link>  */}
                <Link to="https://discord.com/channels/1284005180379103312/1284005180379103315" target="_blank" className="transition-all hover:-mt-2 bg-neutral-900 hover:bg-white lg:w-14 md:w-14 sm:w-11 w-11 lg:h-14 md:h-14 sm:h-11 h-11 flex items-center justify-center rounded-full">
                    <img src={discord} className="w-6 h-6 animate-pulse" />
                </Link> 
                {/* <Link to="/" target="_blank" className="transition-all hover:-mt-2 bg-neutral-900 hover:bg-white lg:w-14 md:w-14 sm:w-11 w-11 lg:h-14 md:h-14 sm:h-11 h-11 flex items-center justify-center rounded-full">
                    <img src={linkdin} className="w-6 h-6 animate-pulse" />
                </Link>  */}
            </div>
            <footer className="mt-10 relative">
                <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                    <div className="md:flex md:justify-between">
                        <div className="mb-6 md:mb-0 lg:w-1/2 md:w-1/2 sm:w-full w-full">
                            <Link to="/" className="flex items-center">
                                <img src={HadesLogo} className="w-48 me-3" alt="FlowBite Logo" />
                            </Link>
                            <div className="lg:w-1/2 md:w-1/2 sm:w-full w-full mt-5">
                                <p className="mb-2 text-footertext text-sm">
                                    Hades ICO: Redefining the Future. Secure, scalable, and sustainable solutions for digital transactions and asset management. Discover unparalleled innovation and excellence.
                                </p>
                            </div>
                        </div>
                        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap gap-8 lg:w-1/2 md:w-1/2 sm:w-full w-full">
                            <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-1/2">
                                <h2 className="mb-6 text-sm font-normal text-white uppercase">Product</h2>
                                <ul className="">
                                    <li className="mb-4">
                                        <Link target="_blank" to="/" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            Developer Guide
                                        </Link>
                                    </li>
                                    <li className="mb-4">
                                        <Link target="_blank" to="/" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            Partner
                                        </Link>
                                    </li>
                                    <li className="mb-4">
                                        <Link target="_blank" to="/" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            Event
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-1/2">
                                <h2 className="mb-6 text-sm font-normal text-white uppercase">Company</h2>
                                <ul className="">
                                    <li className="mb-4">
                                        <Link target="_blank" to="/" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            Careers
                                        </Link>
                                    </li>
                                    <li className="mb-4">
                                        <Link target="_blank" to="/" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            Contact
                                        </Link>
                                    </li>
                                    <li className="mb-4">
                                        <Link target="_blank" to="/" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            Media Kit
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full">
                                <h2 className="mb-6 text-sm font-normal text-white uppercase">Resources</h2>
                                <ul className="">
                                    <li className="mb-4">
                                        <Link target="_blank" to="/" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            Community
                                        </Link>
                                    </li> 
                                    <li className="mb-4">
                                        <Link to="./#how-to-buy" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            How to Buy
                                        </Link>
                                    </li> 
                                    <li className="mb-4">
                                        <Link to="./#Referral" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                        Referral
                                        </Link> 
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-1/4 md:w-1/3 sm:w-full w-full">
                                <h2 className="mb-6 text-sm font-normal text-white uppercase">Legal</h2>
                                <ul className="">
                                    <li className="mb-4">
                                        <Link target="_blank" to="/" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            Privacy
                                        </Link>
                                    </li>
                                    <li className="mb-4">
                                        <Link target="_blank" to="/" className="hover:text-white transition-all text-footertext font-normal no-underline text-sm">
                                            Disclaimer
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 lg:flex lg:justify-end">
                        <span className="text-sm text-gray-500">
                            Contact us: <a href="mailto:hades@aligroup.io" className="text-white hover:underline">hades@aligroup.io                           </a>
                        </span>
                    </div>

                    <hr className="my-6 sm:mx-auto border-black lg:my-8" />

                    <div className="sm:flex sm:items-center sm:justify-between justify-center text-center">
                        <span className="flex items-center justify-center text-sm text-gray-500 sm:text-center">
                            © 2024 &nbsp;
                            <Link to="/" className="text-[#fa4a10] transition-all">
                                HADES ICO
                            </Link>
                            . All Rights Reserved.
                        </span>
                    </div>
                </div>
            </footer>
        </div>
    );
};
export default Footer;
