import React from 'react'

const ComingSoon = () => {
    return (
        <div className='crancy-coming-soon__inside h-screen'> 
            <div className="py-28 text-center"> 
                <div className="text-center mb-5">
                    <h2 className="text-center text-7xl font-bold mb-5 text-gradients bg-gradient-to-r from-[#fa4a10] via-[#e98716] to-[#be2600] inline-block text-transparent bg-clip-text">Coming Soon</h2>
                </div> 
                 
            </div> 
        </div>
    )
}

export default ComingSoon